export const projectList = [
  {
    index: 1,
    tags: [
      "JavaScript",
      "Node",
      "Express",
      "MongoDB",
      "Mongoose",
      "JWT",
      "HTML",
      "Git",
    ],
    projectName: "RoboVA RESTful API and Database",
    projectDesc:
      "RoboVA consists of a RESTful API that follows a model-view-controller architecture. Designed for a small business that offers Virtual Assistant Services. The API performs CRUD operations to manage employees, clients and tasks. Middleware has been implemented to log delete requests and errors. Data is stored within a MongoDB database.",
    projectImg: "robo-screenshot.jpg",
    repoLink: "https://github.com/SarahSalvatore/roboVA-server",
  },
  {
    index: 2,
    tags: [
      "TypeScript",
      "React",
      "Stein API",
      "Google Sheets",
      "CSS",
      "HTML",
      "Git",
      "Netlify",
    ],
    projectName: "Mentor A Promise",
    projectDesc:
      "Created for a NYC-based nonprofit that helps children experiencing homelessness reach their academic potential. The site utilizes Google Sheets as a database. The Stein API performs post requests to Google Sheets which then triggers a Google Apps Script to send an email to the site admin and perform routine form maintenance. Fully responsive and boasts a perfect Lighthouse score for performance, accessibility and best practices",
    projectImg: "mentor-screenshot.jpg",
    demoLink: "https://www.mentorapromise.org",
    repoLink: "https://github.com/SarahSalvatore",
  },
  {
    index: 3,
    tags: [
      "React",
      "Hooks",
      "JavaScript",
      "Sass",
      "HTML",
      "Dictionary API",
      "Git",
      "GitHub Pages",
    ],
    projectName: "Wordle Game",
    projectDesc:
      "The popular word guessing game, Wordle, built with React, React Hooks (useState, useEffect, and useContext), a 2000+ word bank, and Free Dictionary API. The game features a rules modal, colour hints for correct/incorrect letters, and a game end modal displaying the correct word and it's definition. The site is fully responsive and deployed with GitHub Pages. ",
    projectImg: "wordle-screenshot.jpg",
    demoLink: "https://sarahsalvatore.github.io/Wordle/",
    repoLink: "https://github.com/SarahSalvatore/Wordle",
  },
  {
    index: 4,
    tags: [
      "React",
      "JavaScript",
      "Node",
      "Express",
      "MySQL",
      "JWT",
      "Material-UI",
      "Sass",
      "HTML",
      "Git",
    ],
    projectName: "PayMate Payroll App",
    projectDesc:
      "PayMate is a full scale CRUD payroll application designed to streamline the client's payroll process. It allows employees to create and submit timesheets, update their profile and track the status of payment requests. The client can approve/deny consultant invoices, review pay periods and payment status, calculate and generate bonuses, apply pay raises, track quarterly and bi-monthly metrics, and edit consultant profiles.",
    projectImg: "paymate-screenshot.jpg",
    repoLink: "https://github.com/SarahSalvatore",
  },

  {
    index: 5,
    tags: [
      "JavaScript",
      "Node",
      "Express",
      "MongoDB",
      "Mongoose",
      "CSS",
      "HTML",
      "Git",
      "Netlify",
      "Render",
    ],
    projectName: "Condensed URL Shortener",
    projectDesc:
      "Condensed transforms long URLs into more concise, 7 digit slugs. The generated links can be used anywhere and will redirect to the original url. The application consists of a vanilla JavaScript frontend, a Node/Express server and a MongoDB database. The frontend was deployed with Netlify, and the backend was deployed with Render.",
    projectImg: "condensed-screenshot.jpg",
    demoLink: "https://condensed.netlify.app/",
    repoLink: "https://github.com/SarahSalvatore/Condensed-server",
  },
];
