export const skillsList = [
  "TypeScript",
  "JavaScript",
  "React",
  "Node",
  "Express",
  "MySQL",
  "MongoDB",
  "HTML",
  "CSS",
  "Sass",
  "Docker",
  "Git",
];
